import ApiService from "@/common/api.service";

export default {
  cancelAuto(subscriptionId) {
    return ApiService.post(`/subscriptions/${subscriptionId}/cancel`);
  },
  enableAuto(subscriptionId) {
    return ApiService.post(`/subscriptions/${subscriptionId}/resume`);
  }
};
