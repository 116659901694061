<template>
  <div>
    <el-table :data="showPlans" style="width: 100%">
      <el-table-column label="Plan" style="width:25%">
        <template slot-scope="scope">
          <b v-if="scope.row.subscription_plan.name === 'Free'">
            Basic
          </b>
          <b
            v-else-if="scope.row.subscription_plan.id === 6"
            class="test-color"
          >
            Education Max
          </b>
          <b v-else class="test-color">
            {{ scope.row.subscription_plan.name }}
          </b>
        </template>
      </el-table-column>
      <!-- <el-table-column label="Type">
        <template slot-scope="scope">
          <b v-if="scope.row.subscription_plan_price">
            {{ scope.row.subscription_plan_price.nickname }}
          </b>
          <b v-if="scope.row.source === 'organization'">
            Deposit from
            <span class="text-success">Teacher</span>
          </b>
          <b v-if="scope.row.source === 'super_admin'">
            Deposit from
            <span class="text-success">Admin</span>
          </b>
          <b v-if="scope.row.source === 'Free'">
            Free
          </b>
        </template>
      </el-table-column> -->
      <el-table-column label="Price" v-if="!forAdmin" style="width:25%">
        <template slot-scope="scope">
          <b v-if="scope.row.subscription_plan_price"
            >${{ scope.row.subscription_plan_price.amount }}</b
          >
          <b v-else>Free </b>
        </template>
      </el-table-column>
      <el-table-column label="Validity period" style="width:50%">
        <template slot-scope="scope">
          <template v-if="scope.row.period_start && scope.row.period_end">
            <b>{{ moment(scope.row.period_start).format("YYYY-MM-DD") }}</b>
            ~
            <b>{{ moment(scope.row.period_end).format("YYYY-MM-DD") }}</b>
          </template>
          <b v-if="scope.row.source === 'Free'">Free Forever</b>
        </template>
      </el-table-column>
      <!-- <el-table-column label="Next subscription date" v-if="!forAdmin">
        <template slot-scope="scope">
          <template v-if="scope.row.cancel_at_period_end === 0">
            <b>
              {{ moment(scope.row.period_end).format("YYYY-MM-DD") }}
            </b>
          </template>
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column label="Auto-renewal" v-if="!forAdmin">
        <template slot-scope="scope">
          <template v-if="scope.row.subscription_plan_price">
            <div v-show="!scope.row.cancel_at_period_end">
              <el-button
                type="danger"
                size="mini"
                plain
                @click="handleAutoRenewalChange(scope.row)"
              >
                <i class="fa fa-ban"></i>
                Cancel Auto-renewal
              </el-button>
            </div>
            <div v-show="scope.row.cancel_at_period_end">
              <el-button
                type="primary"
                size="mini"
                @click="handleAutoRenewalChange(scope.row)"
              >
                <i class="fa fa-refresh"></i>
                Enable Auto-renewal
              </el-button>
            </div>
          </template>
          <span v-else>-</span>
        </template>
      </el-table-column> -->
    </el-table>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import { user, instant } from "@ivy-way/material";
import moment from "moment";
import Plans from "@/views/profile/apis/plans";

export default {
  metaInfo() {},

  components: {},

  mixins: [],

  props: ["myPlans", "forAdmin"],
  data() {
    return {
      moment
    };
  },
  computed: {
    user() {
      return user;
    },
    instant() {
      return instant;
    },
    showPlans() {
      let showPlan = [];
      if (this.myPlans) {
        showPlan = JSON.parse(JSON.stringify(this.myPlans));
        showPlan.unshift({
          subscription_plan: {
            name: "Free"
          },
          source: "Free",
          period_start: null,
          period_end: null
        });
      }
      return showPlan;
    }
  },
  watch: {},

  mounted() {},

  methods: {
    async handleAutoRenewalChange(plan) {
      try {
        // 根据当前状态确定操作文本（启用或取消）
        const actionText = plan.cancel_at_period_end ? "Enable" : "Cancel";
        // 弹出确认对话框
        await this.$confirm(
          `Are you sure you want to ${actionText} auto-renewal?`,
          "Confirm",
          {
            confirmButtonText: "Confirm",
            cancelButtonText: "Cancel",
            type: "warning"
          }
        );

        // 根据当前状态执行相应操作
        if (!plan.cancel_at_period_end) {
          // 取消自动续订
          await Plans.cancelAuto(plan.id);
        } else {
          // 启用自动续订
          await Plans.enableAuto(plan.id);
        }
        // 切换自动续订状态
        // plan.cancel_at_period_end = !plan.cancel_at_period_end;
        // 显示成功消息
        await this.$emit("getPlans");
        this.$message({
          type: "success",
          message: `Auto-renewal has been ${actionText.toLowerCase()}d`
        });
      } catch (error) {
        // 如果错误不是用户取消操作，则显示错误消息
        if (error !== "cancel") {
          this.$message.error("Operation failed, please try again later");
        }
      }
    }
  }
};
</script>

<style scoped>
.free-color {
  color: #01b884;
}
</style>
